import React from 'react'
import Layout from '@components/layout/Layout'
import SiteLink from '@components/SiteLink'
import SiteMapComponent from '@components/site-map/site-map.component'
import './server-error.scss'

const ServerErrorPage = () => {

    const location = { pathname: "/500" }

    return (
        <Layout location={location}>
            <div className="error-wrapper primary-container">
                <h1>Internal Server Error</h1>
                <p>
                <SiteLink to="/" className="link">
                    Click here
                </SiteLink>{' '}
                to go to the Narcolepsy Link Home page, or{' '}
                <SiteLink to="/stay-connected/about-narcolepsy-link/" className="link">
                    click here
                </SiteLink>{' '}
                to view our About page.
                </p>
                <SiteMapComponent />
            </div>
        </Layout>
    )
}


export default ServerErrorPage
